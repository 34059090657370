const settingTypeOptions = [
  {
    value: "1",
    label: "二维码扫码获取积分",
  },
  {
    value: "2",
    label: "活动签到用二维码",
  },
  {
    value: "11",
    label: "注册获取积分",
  },
  {
    value: "12",
    label: "签到获得积分",
  },
  {
    value: "13",
    label: "点赞获得积分",
  },
  {
    value: "14",
    label: "评论获得积分",
  },
  {
    value: "27",
    label: "时间银行签到用二维码",
  },
];
const createSettingTypeOptions = [
  {
    value: "1",
    label: "二维码扫码获取积分",
  },
  {
    value: "2",
    label: "活动签到用二维码",
  },
  {
    value: "27",
    label: "时间银行活动积分",
  },
];
const settingOnOptions = [
  {
    value: "0",
    label: "关闭",
  },
  {
    value: "1",
    label: "开启",
  },
];
const limitedTypeOptions = [
  {
    value: "1",
    label: "无限制",
  },
  {
    value: "2",
    label: "每日",
  },
  {
    value: "3",
    label: "每月",
  },
  {
    value: "4",
    label: "规定次数",
  },
];

const editTitleMap = {
  update: "编辑",
  create: "添加",
};

export {
  settingTypeOptions,
  createSettingTypeOptions,
  settingOnOptions,
  limitedTypeOptions,
  editTitleMap,
};
