//积分设置列表接口
const pointSettingListUrl = `/gateway/hc-accumulated/pointsetting/getPointSettingList`;
//积分设置详情接口
const pointSettingDetailUrl = `/gateway/hc-accumulated/pointsetting/getPointSettingDetail`;

//新增积分规则接口
const addPointSettingUrl = `/gateway/hc-accumulated/pointsetting/addPointSetting`;
//修改积分规则接口
const updatePointSettingUrl = `/gateway/hc-accumulated/pointsetting/updatePointSetting`;
//修改积分规则开启状态
const changeSettingOnUrl = `/gateway/hc-accumulated/pointsetting/changeSettingOn`;

export {
  pointSettingListUrl,
  pointSettingDetailUrl,
  addPointSettingUrl,
  updatePointSettingUrl,
  changeSettingOnUrl,
};
